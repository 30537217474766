
.activeClass {
    background: #4d5aff;
    color: #ffffff;
}

.company-contain {
    height: calc(100vh - 100px);
    background-color: #ffffff;

    .head-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 10px 30px 0 20px;

        .title {
            margin-top: 10px;
        }
    }

    .divide {
        padding: 0 20px;
    }

    .filter-box {
        .box1 {
            padding: 0 20px 10px 20px;
            height: 40px;
            display: flex;

            span {
                margin-top: 8px;
                line-height: 32px;
                display: inline-block;
                width: 80px;
                height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .college-table {
        .user-box {
            display: flex;
            height: 30px;
            justify-content: start;
            align-items: center;

            .img-box {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }

            span {
                margin-left: 6px;
                max-width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .task-works-content {
            max-width: 260px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
